<template>
  <van-nav-bar
    title="新的密码"
    left-text=""
    left-arrow
    @click-left="onClickLeft"
    safe-area-inset-top
    :border="false"
  />
  <div class="login-box">
    <div style="margin-top:20px">
      <van-cell-group>
        <van-field
          v-model="userPwd"
          type="password"
          placeholder="密码"
          size="large"
        />
        <van-field
          v-model="repeatPwd"
          type="password"
          placeholder="确认密码"
          size="large"
        />
      </van-cell-group>
      <div class="login-btn">
        <van-button
          block
          round
          @click="handleUpdatePwd"
          type="primary"
          style="width:211px;height:48px;margin-top:40px"
          size=""
          >确认修改</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { reactive, toRefs } from "vue";
import { Toast } from "vant";
import { resetPwd } from "@/api/user";
export default {
  setup() {
    const router = useRouter();
    const data = reactive({
      userPwd: "",
      repeatPwd: "",
    });
    const handleUpdatePwd = () => {
      if (data.userPwd == data.repeatPwd) {
        const params = {
          phone: sessionStorage.getItem("updatePwdPhone"),
          newPwd: data.userPwd,
          verificationCode: sessionStorage.getItem("verificationCode"),
        };
        resetPwd(params).then((result) => {
          if (result) {
            Toast.success("密码重置成功,请登录");
            router.push("/login");
            sessionStorage.clear();
          }
        });
      } else {
        Toast({
          message: "两次输入密码不一致，请确认",
          position: "bottom",
        });
      }
    };
    const onClickLeft = () => {
      router.go(-1);
    };
    return {
      onClickLeft,
      handleUpdatePwd,
      ...toRefs(data),
    };
  },
};
</script>

<style lang="scss" scoped>
.login-box {
  text-align: left;
  margin: 30px 8px;
  .login-title {
    font-weight: 600;
    font-size: 30px;
    line-height: 42px;
    color: #171725;
    margin-left: 10px;
  }
  .login-form {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .login-btn {
    display: flex;
    justify-content: center;
  }
}
</style>
